/* Stylesheet */
import "../scss/main.scss";

/* Scripts */
jQuery(document).ready(function () {
    productVariation();
    checkForProductGallerySlider();
    checkForEmployeeSlider();
    checkForProductsSlider();
    // mainMenuSizes();
    filterCategoryFilter();
    checkOfferteBox();
    checkForUspSlider();
});

jQuery(window).resize(function () {});

jQuery(document).scroll(function () {});

jQuery(document).on("click", "button.add-to-quote", function (event) {
    // Disable default action
    event.preventDefault();

    // Get product data
    let productID = jQuery("form.variations_form").data("product_id");
    let productVariation = jQuery("form.variations_form input[name=variation_id]").val();
    let productVariationValue = jQuery("input[name=attribute_pa_staffel]:checked").val();
    let productQuantity = jQuery(".staffel-product-quantity [type='number']").val();
    let quoteItemRef = jQuery("input[name=quote-ref]").val() !== undefined ? jQuery("input[name=quote-ref]").val() : Math.random().toString(36).substring(2, 10);

    // Add product to quote session, save directly to session
    jQuery.ajax({
        url: addProduct.rest_url,
        type: "POST",
        contentType: "application/json",
        headers: {
            "X-WP-Nonce": addProduct.nonce,
        },
        data: JSON.stringify({
            product_id: productID,
            variation_id: productVariation,
            variation_value: productVariationValue,
            quantity: productQuantity,
            ref: quoteItemRef,
        }),
        success: function (response) {
            let cart = document.querySelector("a.quote-cart div");
            cart.innerHTML = Number(cart.innerHTML) + 1;
            showQuoteAddedMessage(response);
        },
    });
});

jQuery(".show-staffel").on("click", function () {
    jQuery("#staffel-info").toggleClass("open");
});

/* Functions */

function productVariation() {
    /* Check for product variation quantity input */
    if (jQuery(".staffel-product-quantity [type='number']").length > 0) {
        // Disable input field
        // jQuery(".staffel-product-quantity [type='number']").prop('disabled', true);

        let staffelQuantity;

        // Check if quote ref is set
        let quoteItemRef = jQuery("input[name=quote-ref]").val() !== undefined ? jQuery("input[name=quote-ref]").val() : Math.random().toString(36).substring(2, 10);

        // If quote ref is set, get product data from session
        let quoteProducts = getCookieValue("quote_products");

        // Check if there is a product in the quote with ref as key
        if (quoteProducts !== "") {
            let products = JSON.parse(quoteProducts);

            if (products[quoteItemRef] !== undefined) {
                // Set .attribute-pa_staffel [type='radio'] checked based on session data
                jQuery("input[name=attribute_pa_staffel][value=" + products[quoteItemRef]["variation_value"] + "]").prop("checked", true);

                jQuery("label.variation-option").removeClass("active");
                jQuery("input[name=attribute_pa_staffel][value=" + products[quoteItemRef]["variation_value"] + "]")
                    .parent()
                    .parent()
                    .addClass("active");

                // Set quantity from session
                staffelQuantity = products[quoteItemRef]["quantity"];
            }
        }
       

        // Update default quantity, get value from current selected variation radio button
        let selectedStaffel = jQuery(".attribute-pa_staffel [type='radio']:checked").val();
        if (staffelQuantity === undefined) {
            staffelQuantity = jQuery('.attribute-pa_staffel span.description[data-term="' + selectedStaffel + '"]').data("quantity");
        }

        jQuery(".staffel-product-quantity [type='number']").val(staffelQuantity);

        // Update quantity on variation change
        jQuery(".attribute-pa_staffel [type='radio']").change(function () {
            jQuery("label.variation-option").removeClass("active");
            jQuery(this).parent().parent().addClass("active");

            let selectedStaffel = jQuery(this).val();
            let description= jQuery('.attribute-pa_staffel span.description[data-term="' + selectedStaffel + '"]');
            let staffelQuantity = description.data("quantity");

            jQuery(".staffel-product-quantity [type='number']").val(staffelQuantity);
            jQuery('div.quantity').css('--data-stuks', '"'+description.data("stuks")+'"');
        });

        document.querySelectorAll('[name="attribute_pa_staffel"]').forEach(function(radio){
            radio.addEventListener('click',function(){
                
            });
        });
        document.querySelectorAll('.variation-option.active input').forEach(function(radio){
            radio.click();
        })

        // Update quantity on staffel-down button click
        jQuery(".staffel-product-quantity .staffel-down").click(function () {
            let currentQuantity = parseInt(jQuery(".staffel-product-quantity [type='number']").val());
            let selectedStaffel = jQuery(".attribute-pa_staffel [type='radio']:checked").val();
            let staffelQuantity = jQuery('.attribute-pa_staffel span.description[data-term="' + selectedStaffel + '"]').data("quantity");

            if (currentQuantity > staffelQuantity) {
                jQuery(".staffel-product-quantity [type='number']").val(currentQuantity - staffelQuantity);
            }
        });

        // Update quantity on staffel-up button click
        jQuery(".staffel-product-quantity .staffel-up").click(function () {
            let currentQuantity = parseInt(jQuery(".staffel-product-quantity [type='number']").val());
            let selectedStaffel = jQuery(".attribute-pa_staffel [type='radio']:checked").val();
            let staffelQuantity = jQuery('.attribute-pa_staffel span.description[data-term="' + selectedStaffel + '"]').data("quantity");

            jQuery(".staffel-product-quantity [type='number']").val(currentQuantity + staffelQuantity);
        });
    }
}

jQuery(".quote-overview").on("click", ".remove-product", function () {
    let productRef = jQuery(this).data("item");
    let productTitle = jQuery(".item-" + productRef + " .title h3").html();
    if (productref) {
        productTitle += " - " + jQuery(".item-" + productRef + " .title span").html();
    }
    if (confirm("Weet je zeker dat je product '" + productTitle + "' uit je offertedoos wilt verwijderen?")) {
        jQuery.ajax({
            url: removeProduct.rest_url,
            type: "POST",
            contentType: "application/json",
            headers: {
                "X-WP-Nonce": removeProduct.nonce,
            },
            data: JSON.stringify({
                ref: productRef,
            }),
            success: function (response) {
                jQuery(".item-" + productRef).remove();
            },
        });
    } else alert("gelukkig!");
});

function showQuoteAddedMessage(response) {
    jQuery("body").append(
        '<div id="product-added-modal"><div class="wrapper">' +
            "<div><span>" +
            jQuery(".product_title.entry-title").html() +
            "</span> is toegevoegd aan jouw offertedoos</div>" +
            '<div><a class="btn close"><span>Verder winkelen</span></a><a href="/offertedoos" class="btn dark show"><span>Bekijk offertedoos</span></a></div>' +
            "</div></div>"
    );
}

jQuery("body").on("click", "#product-added-modal .close", function () {
    jQuery("#product-added-modal").remove();
});

function checkForProductGallerySlider() {
    if (jQuery(".product-gallery-slider").length > 0) {
        jQuery(".product-gallery-slider .slider").slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            dots: true,
            infinite: true,
        });
    }
}

function checkForProductsSlider() {
    jQuery(".products-slider").each(function () {
        jQuery(".products", this).slick({
            slidesToShow: 4,
            slidesToScroll: 4,
            arrows: true,
            dots: true,
            infinite: true,
            appendDots: jQuery(".slide-m-dots", this),
            prevArrow: jQuery(".slide-m-prev", this),
            nextArrow: jQuery(".slide-m-next", this),
            responsive: [
                {
                    breakpoint: 1400,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    },
                },
                {
                    breakpoint: 1100,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    },
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },
            ],
        });
    });
}

function checkForUspSlider() {
    if (jQuery(".usps-slider").length > 0) {
        jQuery(".usps-slider").slick({
            slidesToShow: 2,
            slidesToScroll: 1,
            arrows: false,
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 0,
            speed: 8000,
            cssEase: "linear",
            waitForAnimate: false,
            pauseOnFocus: false,
            pauseOnHover: false,
            responsive: [
                {
                    breakpoint: 680,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        speed: 6000,
                    },
                },
            ],
        });
    }
}

function checkForEmployeeSlider() {
    if (jQuery(".employees-section").length > 0) {
        jQuery(".employees-section .team-members").slick({
            slidesToShow: 4,
            slidesToScroll: 1,
            arrows: true,
            dots: true,
            appendDots: jQuery(".slick-dots"),
            appendArrows: jQuery(".slick-slider-nav"),
            infinite: true,
            responsive: [
                {
                    breakpoint: 1050,
                    settings: {
                        slidesToShow: 3,
                    },
                },
                {
                    breakpoint: 850,
                    settings: {
                        slidesToShow: 2,
                    },
                },
                {
                    breakpoint: 680,
                    settings: {
                        slidesToShow: 1,
                    },
                },
            ],
        });
    }
}

// function mainMenuSizes()
// {
//   jQuery('.max-mega-menu > .mega-menu-item').each(function()
//   {
//     // Count submenu columns
//     let columns = jQuery(this).find('> .mega-sub-menu .mega-menu-row:first-child .mega-menu-column').length;
//     let column_width = 280;

//     // Set width of submenu based on columns, only when screen width is larger than 1130px
//     if( jQuery(window).width() > 1130 )
//     {
//       jQuery(this).find('> .mega-sub-menu').css('width', columns * column_width + 'px');
//     }

//     // Set position of submenu based on parent menu item position
//     jQuery(this).find('> .mega-sub-menu').css('left', jQuery(this).position().left + 'px');
//   });

// }

/* Cookies */
function getCookieValue(cname) {
    // cname is the cookie name (foo) which value you are after
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

window.checkBoxSearch = function checkBoxSearch(form) {
    let emptyCounter = 0;
    let errorCounter = 0;
    jQuery('input[type="number"]', form).each(function (index, obj) {
        // Check for valid input
        if (jQuery(this).val() !== "" && jQuery(this).val() < 100) {
            jQuery(this).parent().find(".error").html("Te kleine waarde. Heb je het in millimeters ingevuld?").show();
            errorCounter++;
        } else if (jQuery(this).val() !== "" && jQuery(this).val() > 5000) {
            jQuery(this).parent().find(".error").html("Heb je het in millimeters ingevuld? (Maximale waarde 5000)").show();
            errorCounter++;
        } else {
            jQuery(this).parent().find(".error").hide();
        }

        // Check for empty input, then add 1 to empty counter variable
        if (jQuery(this).val() === "") {
            emptyCounter++;
        }
    });

    // If all inputs are empty, show error message
    if (emptyCounter === jQuery('input[type="number"]', form).length) {
        jQuery(".error", form).html("Vul tenminste een van deze velden").show();
        errorCounter++;
    }

    // If no errors, submit form
    if (errorCounter === 0) {
        // Remove all empty input fields
        jQuery('input[type="number"]', form).each(function (index, obj) {
            if (jQuery(this).val() === "") {
                jQuery(this).attr("name", "").attr("disabled", "disabled");
            }
        });
        return true;
    } else {
        return false;
    }
};

function filterCategoryFilter() {
    // Extract the specific product category ID from the body class
    var bodyClasses = jQuery("body").attr("class").split(" ");
    var specificTermId = null;

    bodyClasses.forEach(function (className) {
        if (className.startsWith("term-")) {
            specificTermId = className.split("-")[1];
        }
    });

    if (specificTermId) {
        // Hide all list items initially
        jQuery(".wpc-filter-product_cat .wpc-filters-ul-list .wpc-checkbox-item").hide();

        // Show the specific product category and its children
        jQuery("#wpc-term-taxonomy-product_cat-" + specificTermId)
            .parentsUntil(".wpc-filters-ul-list")
            .show();
        jQuery("#wpc-term-taxonomy-product_cat-" + specificTermId).show();
        jQuery("#wpc-term-taxonomy-product_cat-" + specificTermId)
            .find(".wpc-checkbox-item")
            .show();

        // Hide direct parent category if it exists
        //jQuery('#wpc-term-taxonomy-product_cat-' + specificProductId).parents('.wpc-checkbox-item').first().children('.wpc-term-item-content-wrapper').hide();

        // Show the specific product category and its children
        var specificCategory = jQuery("#wpc-term-taxonomy-product_cat-" + specificTermId);
        specificCategory.parentsUntil(".wpc-filters-ul-list").show();
        specificCategory.show();
        specificCategory.find(".wpc-checkbox-item").show();

        // Hide all parent categories of the specific category
        specificCategory.parents(".wpc-checkbox-item").each(function () {
            jQuery(this).children(".wpc-term-item-content-wrapper").hide();
        });

        // Hide label of the specific category
        jQuery(".wpc-term-id-" + specificTermId + " > .wpc-term-item-content-wrapper").hide();
    }
}

// Toggle filter
jQuery(".product-filter-results-wrapper .toggle-filter").on("click", function () {
    jQuery(".search-filter-column").toggleClass("open");
    jQuery("body").toggleClass("category-filter-open");
});

// Close filter on clicking outside of it
jQuery(document).on("click", function (event) {
    if (!jQuery(event.target).closest(".search-filter-column").length && !jQuery(event.target).closest(".toggle-filter").length) {
        jQuery(".search-filter-column").removeClass("open");
        jQuery("body").removeClass("category-filter-open");
    }
});

function checkOfferteBox() {
    if (jQuery(".different-delivery-address").length > 0) {
        // Check if delivery address checkbox is checked
        if (jQuery('.different-delivery-address input[type="checkbox"]').is(":checked")) {
            jQuery(".delivery-address-section").parent().show();
        } else {
            jQuery(".delivery-address-section").parent().hide();
        }
    }

    // Fill product list inout field with product data from session
    jQuery(".products-list textarea").val(jQuery("#product-list-for-form").html());
}

jQuery(".different-delivery-address").on("change", function () {
    checkOfferteBox();
});

/* Custom file upload */
jQuery("form #file-upload").on("change", function () {
    let file = jQuery(this).prop("files")[0];
    let fileName = file.name;
    jQuery(this).parent().find(".custom-file-select").html(fileName);
});

/* Custom quote validation */
jQuery("#custom-quote").on("submit", function (e) {
    e.preventDefault();

    let isValid = true;

    jQuery("input").next(".form-error").remove();

    // Validate Bedrijfsnaam
    if (jQuery("#companyname").val().trim() === "") {
        jQuery("#companyname").after('<div class="form-error">Bedrijfsnaam is verplicht.</div>').focus();
        return;
    }

    // Validate Naam
    if (jQuery("#name").val().trim() === "") {
        jQuery("#name").after('<div class="form-error">Naam is verplicht.</div>').focus();
        return;
    }

    // Validate Telefoonnummer
    if (jQuery("#phone").val().trim() === "") {
        jQuery("#phone").after('<div class="form-error">Telefoonnummer is verplicht.</div>').focus();
        return;
    }

    // Validate Email
    let email = jQuery("#email").val().trim();
    if (email === "") {
        jQuery("#email").after('<div class="form-error">Email is verplicht.</div>').focus();
        return;
    } else if (!validateEmail(email)) {
        jQuery("#email").after('<div class="form-error">Voer een geldig emailadres in.</div>').focus();
        return;
    }

    // If all validations pass, submit the form
    if (isValid) {
        this.submit();
    }
});

// Email validation function
function validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/i;
    return re.test(String(email).toLowerCase());
}

document.querySelectorAll('input[name="srch"],input[name="s"]').forEach((input) => {
    input.addEventListener("input", function (e) {
        if (e.target.value.match(/(\d)*x(\d)*/i)) {
            e.target.value = e.target.value.replace(/(\d)x(\d)/i, "$1 x $2");
        }
        if (e.target.value.match(/(\d)*x /i)) {
            e.target.value = e.target.value.replace(/(\d)*x /i, "$1 x $2");
        }
        if (e.target.value.match(/ x (\d)*/i)) {
            e.target.value = e.target.value.replace(/ x(\d)*/i, "$1 x $2");
        }
    });
});
